import BillSplitterApp from './pages/BillSplitterApp';

const App = () => {
  return (
    <div>
      <header>
       <h1 className='main-title'>SPLI</h1>
      <h1 className='main-title'>TTER</h1>
      </header>
      <BillSplitterApp />

    </div>
  )
}

export default App
